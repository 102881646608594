<template>
    <div class="Switcher" @click="() => onClick()">
        <button
            type="button"
            class="Switcher__btn"
            :class="isActive ? 'Switcher__btn Switcher__btn--active' : 'Switcher__btn'"
        ></button>
        <p class="Switcher__text">{{ switcherText }}</p>
    </div>
</template>

<script>
export default {
    name: "Switcher",

    props: {
        isActive: Boolean,
        switcherText: String,
        onClick: Function,
    },
};
</script>

<style lang="scss" scoped>
@import "./Switcher.scss";
</style>
