<template>
    <Layout>
        <PageHeader :items="items" :title="title" />

        <div class="profileSection">
            <ProfileAside />
            <div class="profileContent">
                <div class="profileContentRow">
                    <div class="profileContentCol" v-if="updatedNotifications.length">
                        <div
                            class="profileBox"
                            v-for="item in updatedNotifications"
                            :key="item._id"
                        >
                            <p class="title1 profileBox__title">
                                {{ item.course.name }}
                            </p>

                            <div class="profileSwitchers">
                                <div
                                    class="profileSwitchers__item"
                                    v-for="subitem in item.notificationSettings"
                                    :key="subitem.id"
                                >
                                    <Switcher
                                        v-if="
                                            subitem.type === 'email' ||
                                                subitem.type === 'site'
                                        "
                                        :isActive="subitem.active"
                                        :value="subitem.active"
                                        :switcherText="subitem.title"
                                        :onClick="() => onToggleButton(subitem, item._id)"
                                    />

                                    <img
                                        v-if="subitem.type === 'email'"
                                        class="profileSwitchers__itemIcon"
                                        src="@/assets/img/pages/profile/mail.svg"
                                        alt="icon"
                                    />
                                    <img
                                        v-if="subitem.type === 'site'"
                                        class="profileSwitchers__itemIcon"
                                        src="@/assets/img/pages/profile/push.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="form__submit">
                            <Button
                                title="Сохранить"
                                extraClass="button"
                                :onClick="() => changeNotificationSetting()"
                                :isDisabled="updatedCourses.length === 0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ProfileAside from "@/views/partials/ProfileAside/ProfileAside";
import Switcher from "@/views/components/Switcher/Switcher";
import Button from "@/views/components/Button/Button";
import cloneDeep from "lodash/cloneDeep";

export default {
    name: "profileNotifications",

    components: {
        Layout,
        Switcher,
        PageHeader,
        ProfileAside,
        Button,
    },

    watch: {
        notificationSettings: function() {
            let notifications = [];

            for (const item of this.notificationSettings) {
                const notificationKeys = Object.keys(item.notificationSettings);

                let updatedNotification = [];
                for (const key of notificationKeys) {
                    const payload = {
                        type: key,
                        id: `${Math.random()}${key}`,
                        active: item.notificationSettings[key],
                    };

                    if (key === "email") payload.title = "Получать уведомления на email";
                    if (key === "site") payload.title = "Получать уведомления на сайте";

                    updatedNotification.push(payload);
                }

                item.notificationSettings = updatedNotification.sort(function(a, b) {
                    if (a.type < b.type) {
                        return -1;
                    }
                });

                notifications.push(item);
            }

            this.updatedNotifications = notifications;
        },
    },

    data() {
        return {
            title: "Профиль",
            items: [
                {
                    text: "Магазин программ",
                    to: "/",
                },
                {
                    text: "Профиль",
                    active: true,
                },
            ],

            updatedNotifications: [],

            updatedCourses: [],
        };
    },

    async beforeMount() {
        this.updateNotificationSettings();
    },

    computed: {
        notificationSettings() {
            return this.$store.getters.getNotificationSettings.notificationSettings
                .membersSettings;
        },
    },

    methods: {
        onToggleButton(value, id) {
            const courseToUpdate = this.updatedNotifications.find(
                (item) => item._id === id
            );

            for (let key in courseToUpdate.notificationSettings) {
                if (courseToUpdate.notificationSettings[key] === value) {
                    courseToUpdate.notificationSettings[key].active = !courseToUpdate
                        .notificationSettings[key].active;
                }
            }

            this.updatedCourses = this.notificationSettings;
        },

        async updateNotificationSettings() {
            const getNotificationSettings = await this.axios.get(
                "/notifications/" + "members"
            );

            if (
                getNotificationSettings &&
                getNotificationSettings.data &&
                getNotificationSettings.data.result
            ) {
                await this.$store.dispatch(
                    "setNotificationSettings",
                    getNotificationSettings.data
                );
            } else {
                console.error(
                    "Ошибка получения уведомлений: " +
                        (getNotificationSettings.data.message || "")
                );
                await this.$store.dispatch("clearNotificationSettings");
            }
        },

        changeNotificationSetting: async function() {
            const payload = cloneDeep(this.updatedNotifications);

            for (const item of payload) {
                const result = {};
                for (const key of item.notificationSettings) {
                    result[key.type] = key.active;
                }

                item.notificationSettings = result;
                delete item.course;
            }

            const notificationSettingsBody = {
                membersSettings: payload,
            };
            const response = await this.axios.post(
                `/notifications/members`,
                notificationSettingsBody
            );
            if (response && response.data && response.data.result) {
                this.updatedCourses = [];
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Новые настройки уведомлений успешно сохранены",
                    },
                });
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            "К сожалению не удалось сохранить новые настройки уведомлений",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "profileNotifications.scss";
</style>
